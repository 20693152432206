import React from "react"
import { ContentSection } from "~components/atoms/ContentSection"
import { GridColumn, GridRow } from "~components/atoms/Grid"

import { DefaultLayout } from "~components/layout/Default"
import { SEO } from "~components/Seo"

const NotFoundPage = () => (
    <DefaultLayout
        activeDocMeta={{
            lang: "de-de",
            alternateLanguages: [
                {
                    type: "home",
                    lang: "en-gb",
                    url: "/en/404",
                },
                {
                    type: "home",
                    lang: "zh-cn",
                    url: "/zh/404",
                },
            ],
        }}
    >
        <ContentSection>
            <GridRow width="content">
                <GridColumn s={12} m={12} l={12}>
                    <SEO title="404: Not found" />
                    <h1>404: Not Found</h1>
                    <p>You just hit a route that doesn&#39;t exist...</p>
                </GridColumn>
            </GridRow>
        </ContentSection>
    </DefaultLayout>
)

export default NotFoundPage
